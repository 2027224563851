



















	import { Vue, Component } from 'vue-property-decorator'
	import { namespace, Action, State } from 'vuex-class' 
	import { mapActions } from 'vuex'
	import PageHeader from '@/components/common/PageHeader.vue'
	@Component({
		components: {
		  PageHeader
		},
		methods: {
			...mapActions([
				'fetchQrcodes'
			])
		}
	})
	export default class QRCodes extends Vue {

		@State(state => state.dashboard.qrcodes) qrcodes!: Array<object>

		private authorized: boolean = false

		private limit: number = 100

		private skip: number = 0

		private breadcrumb: any = [
      {label: 'Mini-Program Management'},
      {label: 'Dashboard'},
    ]

		fetchQrcodes: (params: {limit: number, skip: number}) => any

		async mounted () {
			await this.fetchQrcodes({limit: this.limit, skip: this.skip})
		}

	}
